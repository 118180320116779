<template>
  <div class="block hover:bg-gray-200 my-1 px-4 py-1 font-medium text-gray-800 text-right">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AppDropdownItem",
};
</script>
