<script setup>
import store from "@/store";
import { computed, ref, defineProps, onMounted, onUnmounted } from "vue";
import router from "../router";
import AppDropdown from "./Dropdown/AppDropdown.vue";
import AppDropdownContent from "./Dropdown/AppDropdownContent.vue";
import AppDropdownItem from "./Dropdown/AppDropdownItem.vue";
import { useRouter, useRoute } from "vue-router";

const route = useRoute();

const props = defineProps({
  mode: {
    type: String,
    default: "dark",
  },
});

const modeClass = computed(() => {
  if (props.mode === "light") {
    return {};
  }

  return {};
});

const loading = ref(false);

const login = async () => {
  router.push({ name: "Login" });

  // setMobileOpen(false);
  // loading.value = true;
  // await store.dispatch("auth/login");
  // loading.value = false;
};

const logout = async () => {
  setMobileOpen(false);
  loading.value = true;
  await store.dispatch("auth/logout");
  loading.value = false;
};

const isLoggedIn = computed(() => {
  return store.getters["auth/isLoggedIn"];
});
const address = computed(() => {
  if (!store.state.auth.user?.username) {
    return null;
  }

  if (store.state.auth.user.username.length > 20) {
    return `${store.state.auth.user.username?.substring(
      0,
      7
    )}...${store.state.auth.user.username?.substring(store.state.auth.user.username?.length - 7)}`;
  }

  return store.state.auth.user.username;
});

const gotoprofile = () => {
  router.push({ name: "Profile" });
};

const gotogame = () => {
  router.push({ name: "Poker" });
};

const gotogallery = () => {
  router.push({ name: "Gallery" });
};

const gotoabout = () => {
  router.push({ name: "About" });
};

const gotovision = () => {
  router.push({ name: "Vision" });
};

const gotoseries = () => {
  router.push({ name: "Series" });
};

const gotoarcade = () => {
  router.push({ name: "Arcade" });
};

const gotobook = () => {
  router.push({ name: "Book" });
};

const gotobuy = () => {
  router.push({ name: "Buy" });
};

const gotogames = () => {
  router.push({ name: "Games" });
};

const mobileOpen = ref(false);
const setMobileOpen = (set) => {
  mobileOpen.value = set;
};

const inRoute = (routes) => {
  return !!routes.find((r) => route.name === r);
};

const version = process.env.VUE_APP_PACKAGE_VERSION;

const handleScroll = (event) => {
  if (window?.scrollY > 0) {
    isAtTop.value = false;
  } else {
    isAtTop.value = true;
  }
};

const isAtTop = ref(true);

onMounted(() => {
  window?.addEventListener("scroll", handleScroll);
  handleScroll();
});

onUnmounted(() => {
  window?.removeEventListener("scroll", handleScroll);
});
</script>

<template>
  <div class="newnav-placeholder"></div>
  <div class="newnav" :class="[props.mode, { attop: isAtTop }]">
    <div class="container flex items-center justify-between py-4">
      <div class="left h-10 flex-shrink md:flex-shrink-0 pr-4">
        <router-link :to="{ name: 'Home' }">
          <img
            :src="require('@/assets/home/soth.svg')"
            style="height: 100%"
            v-if="mode === 'light'"
          />
          <img :src="require('@/assets/home/soth-blue.svg')" style="height: 100%" v-else />
        </router-link>
      </div>

      <div class="right">
        <div class="newnav-links">
          <!-- <router-link
            class="newnav-link"
            :to="{ name: 'Buy' }"
            :class="[props.mode, { 'selected-buy': inRoute(['Buy', 'Mint']) }]"
          >
            Buy
          </router-link> -->
          <!-- <router-link
            class="newnav-link"
            :to="{ name: 'About' }"
            :class="[props.mode, { 'selected-about': inRoute(['About']) }]"
          >
            About
          </router-link> -->
          <!-- <router-link
            class="newnav-link"
            :to="{ name: 'Vision' }"
            :class="[props.mode, { 'selected-vision': inRoute(['Vision']) }]"
            >Vision</router-link
          > -->
          <!-- <router-link
            class="newnav-link"
            :to="{ name: 'Book' }"
            :class="[props.mode, { 'selected-book': inRoute(['Book']) }]"
            >Book</router-link
          > -->
          <!-- <router-link
            class="newnav-link"
            :to="{ name: 'Series' }"
            :class="[props.mode, { 'selected-series': inRoute(['Series']) }]"
            >Animated Series</router-link
          > -->
          <router-link
            class="newnav-link"
            :to="{ name: 'Gallery' }"
            :class="[props.mode, { 'selected-gallery': inRoute(['Gallery']) }]"
            >Gallery</router-link
          >
          <router-link
            class="newnav-link"
            :to="{ name: 'Games' }"
            :class="[props.mode, { 'selected-series': inRoute(['Games']) }]"
          >
            Mobile Games
          </router-link>
          <router-link
            class="newnav-link"
            :to="{ name: 'Arcade' }"
            :class="[
              props.mode,
              {
                'selected-arcade': inRoute([
                  'Arcade',
                  'Poker',
                  'PokerStandard',
                  'ArcadeTrivia',
                  'PortalRedemption',
                ]),
              },
            ]"
          >
            Online Arcade
          </router-link>
          <a
            href="https://twitter.com/hourglass_nft"
            target="_blank"
            class="newnav-link"
            :class="[props.mode]"
          >
            <img :src="require('@/assets/home/twitter.svg')" v-if="mode === 'light'" />
            <img :src="require('@/assets/home/twitter-blue.svg')" v-else />
          </a>
          <a
            href="https://discord.gg/vKMjvxwuQP"
            target="_blank"
            class="newnav-link"
            :class="[props.mode]"
          >
            <img :src="require('@/assets/home/discord.svg')" v-if="mode === 'light'" />
            <img :src="require('@/assets/home/discord-blue.svg')" v-else />
          </a>
          <a
            href="https://opensea.io/collection/society-of-the-hourglass"
            target="_blank"
            class="newnav-link"
            :class="[props.mode]"
          >
            <img :src="require('@/assets/home/opensea.svg')" v-if="mode === 'light'" />
            <img :src="require('@/assets/home/opensea-blue.svg')" v-else />
          </a>
        </div>
        <div class="auth">
          <div>
            <button
              class="relative flex items-center focus:outline-none pl-5 pr-3 py-2 px-24 rounded-lg bg-ch1blue text-white font-semibold"
              @click="setMobileOpen(!mobileOpen)"
            >
              <template v-if="!address">
                Menu
                <svg
                  style="transform: rotateZ(90deg)"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="ml-1 h-5 w-5 fill-current text-white"
                >
                  <path
                    d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
                  ></path>
                </svg>
              </template>
              <template v-if="address">
                {{ address }}
                <svg
                  style="transform: rotateZ(90deg)"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="ml-1 h-5 w-5 fill-current text-white"
                >
                  <path
                    d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
                  ></path>
                </svg>
              </template>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mobile-menu" :class="{ open: mobileOpen }" @click="setMobileOpen(false)">
    <div class="mobile-inner" @click.stop="() => {}">
      <div class="mobile-menu-item close">
        <button class="mobile-menu-item-inner" @click="setMobileOpen(false)">Close Menu</button>
      </div>
      <!-- <div
        class="mobile-menu-item block xl:hidden"
        :class="{ 'selected-about-mobile': inRoute(['Buy', 'Mint', 'BuyCredit']) }"
      >
        <button class="mobile-menu-item-inner" @click="gotobuy">Buy</button>
      </div> -->
      <!-- <div
        class="mobile-menu-item block xl:hidden"
        :class="{ 'selected-about-mobile': inRoute(['About']) }"
      >
        <button class="mobile-menu-item-inner" @click="gotoabout">About</button>
      </div> -->
      <!-- <div
        class="mobile-menu-item block xl:hidden"
        :class="{ 'selected-vision-mobile': inRoute(['Vision']) }"
      >
        <button class="mobile-menu-item-inner" @click="gotovision">Vision</button>
      </div> -->
      <!-- <div class="mobile-menu-item block xl:hidden">
        <button class="mobile-menu-item-inner" @click="gotobook">Book</button>
      </div> -->
      <!-- <div class="mobile-menu-item block xl:hidden">
        <button class="mobile-menu-item-inner" @click="gotoseries">Animated Series</button>
      </div> -->
      <div class="mobile-menu-item block xl:hidden">
        <button
          class="mobile-menu-item-inner"
          @click="gotogallery"
          :class="{ 'selected-gallery-mobile': inRoute(['Gallery']) }"
        >
          Gallery
        </button>
      </div>
      <div class="mobile-menu-item block xl:hidden">
        <button
          class="mobile-menu-item-inner"
          @click="gotogames"
          :class="{ 'selected-gallery-mobile': inRoute(['Games']) }"
        >
          Mobile Games
        </button>
      </div>
      <div class="mobile-menu-item block xl:hidden">
        <button
          class="mobile-menu-item-inner"
          @click="gotoarcade"
          :class="{
            'selected-arcade-mobile': inRoute(['Arcade', 'Poker', 'PokerStandard']),
          }"
        >
          Online Arcade
        </button>
      </div>
      <div class="mobile-menu-item block xl:hidden">
        <a href="https://twitter.com/hourglass_nft" target="_blank" class="mobile-menu-item-inner"
          >Twitter</a
        >
      </div>
      <div class="mobile-menu-item block xl:hidden">
        <a
          href="https://discord.gg/vKMjvxwuQP"
          target="_blank"
          class="mobile-menu-item-inner"
          @click="gotogame"
          >Discord</a
        >
      </div>
      <div class="mobile-menu-item block xl:hidden">
        <a
          href="https://opensea.io/collection/society-of-the-hourglass"
          target="_blank"
          class="mobile-menu-item-inner"
          @click="gotogame"
          >OpenSea</a
        >
      </div>
      <div class="mobile-menu-item" v-if="isLoggedIn">
        <button class="mobile-menu-item-inner" @click="gotoprofile">Your Profile</button>
      </div>
      <div class="mobile-menu-item">
        <button class="mobile-menu-item-inner" @click="logout" v-if="isLoggedIn">Logout</button>
        <button class="mobile-menu-item-inner" @click="login" v-else>Login</button>
      </div>
      <div>
        <div class="w-full text-center bottom-0 left-0 font-sans text-gray-400 pt-4 pb-2 text-xs">
          &copy; Society of the Hourglass 2022 - v{{ version }}
        </div>
        <div class="w-full text-center bottom-0 left-0 font-sans text-gray-400 pb-4 pt-2 text-xs">
          <router-link :to="{ name: 'TermsCollection' }">Terms & Conditions </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="pcss" scoped>
.newnav-placeholder {
  @apply h-16 xl:h-24;
}
.newnav {
  @apply bg-white bg-opacity-10 w-full top-0 h-16 xl:h-24 flex items-center z-nav transition-all duration-500;
}

.newnav.dark {
  @apply fixed shadow xl:shadow-none bg-opacity-20;
}
.newnav.light {
  @apply fixed bg-black bg-opacity-30;
}
.newnav.attop {
  @apply bg-opacity-0;
}
.newnav.top {
  box-shadow: 0px 8px 0px rgba(0, 0, 0, 0.25);
  @apply absolute;
}
.newnav-links {
  @apply hidden font-maindisplay xl:flex text-sm items-center;
}

.newnav-link {
  @apply px-4 py-2 rounded bg-opacity-2 bg-ch1blue font-brother mx-2 text-ch1blue hover:bg-opacity-25 font-bold;
}
.newnav-link.light {
  @apply text-white bg-opacity-0 hover:bg-opacity-25;
}
.newnav-link.top {
  @apply bg-opacity-0 hover:bg-opacity-10;
}

.newnav-link .router-link-active {
  @apply font-black bg-ch1blue-light bg-opacity-30;
}

.social {
  @apply flex items-center;
}
.right {
  @apply flex items-center;
}

.mobile-menu {

  @apply fixed w-full h-screen transform translate-x-full transition flex justify-end items-stretch top-0 right-0 z-navOver;
}
.mobile-menu.open {
  @apply translate-x-0;
}
.mobile-inner {
 box-shadow: -4px 4px 0px rgba(0, 0, 0, 0.12);
  @apply w-3/4 md:w-1/2 lg:w-1/4 bg-white h-full overflow-auto;
}
.mobile-menu-item {
  @apply w-full p-2 border-b border-gray-200;
}
.mobile-menu-item.close {
  @apply w-full p-2 border-b border-gray-200 bg-ch1blue-dark text-white;
}
.mobile-menu-item.close .mobile-menu-item-inner {
  @apply text-white hover:bg-ch1blue-light;
}
.mobile-menu-item-inner {
  @apply block font-brother font-bold hover:bg-gray-100 p-2 cursor-pointer rounded w-full text-left text-ch1blue;
}
.mobile-menu-item-inner button {
  @apply font-bold;
}

.newnav-link.selected-about {
  @apply bg-blue bg-opacity-30 hover:bg-opacity-40;
}

.newnav-link.selected-vision {
  @apply bg-blue bg-opacity-30 hover:bg-opacity-40;
}

.newnav-link.selected-buy {
  @apply bg-sothyellow bg-opacity-30 hover:bg-opacity-40;
}

.newnav-link.selected-series {
  @apply bg-ch1red bg-opacity-30 hover:bg-opacity-40;
}

.newnav-link.selected-arcade {
  @apply bg-ch1red bg-opacity-30 hover:bg-opacity-40;
}

.newnav-link.selected-gallery {
  @apply bg-sothyellow bg-opacity-30 hover:bg-opacity-40;
}
</style>
